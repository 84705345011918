@import '../variables.less';

@import 'animate.less/animate.less';

.animation-show-hide.ng-hide-remove.ng-hide-remove-active,
.animation-if.ng-enter.ng-enter-active
 {
  -webkit-animation: 0.75s fadeIn ease;
  -moz-animation: 0.75s fadeIn ease;
  animation: 0.75s fadeIn ease;
  background-color: #efefef;
  -webkit-transition: background-color 1.2s ease-in;
  -moz-transition: background-color 1.2s ease-in;
  transition: background-color 1.2s ease-in;
}

.animation-show-hide.ng-hide,
.animation-if.ng-leave {
  opacity: 0;
  -webkit-animation: 0.5s fadeOut ease;
  -moz-animation: 0.5s fadeOut ease;
  animation: 0.5s fadeOut ease;
}

.animation-show-hide,
.animation-if {
  background-color: @body-bg;
  /* padding: 10px 10px 10px 10px; */
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}
