@import 'variables.less';
@import 'notifications.less';

body {
  font-size: 13px;
  font-family: "Source Sans Pro";
}

img {
  max-width: 100%;
}

.bottom-header {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 50px;
  background-color: #2c96bc;
  z-index: 1;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  i {
    margin: 0 5px 0 0;
  }
  a {
    color: #fff;
  }
}

.alert-callout {
  .alert-variant(@alert-callout-bg; @alert-callout-border; @alert-callout-text);
}

.btn-inverse {
  .button-variant(#fff; @inverse; darken(@inverse, 10%));
}

.strike-through * {
  text-decoration: line-through;
}

.panel {
  box-shadow: rgba(50, 50, 50, 0.2) 3px 3px 1em;

  .panel-heading {
    &.letter-code-container {
      display: flex;

      .panel-title {
        flex-grow: 1;
      }
    }
  }
}

.disabled-panel {
  color: #ccc;

  &.panel {
    border-color: #b0bdc5;
    box-shadow: rgba(50, 50, 50, 0.1) 3px 3px 1em;

    .panel-heading {
      background-color: #b0bdc5;
      border-color: #b0bdc5;
    }
  }
}

label, .control-label, .help-block, .checkbox, .radio {
  font-size: 1em;
}

.no-tooltip-text .help-tooltip-display {
  display: none;
}

.noTopPadding {
  padding-top: 0px;
}

.noBottomMargin {
  margin-bottom: 0px;
}

table.no-border tbody tr td {
  border: none;
}

.navbar {
  margin-bottom: 0px;
  min-height: 45px;

  a.btn.btn-primary {
    background-color: #79935C;
    border-color: #76917D;
    &:hover {
      background-color: #627A56;
      border-color: #5F7A66;
    }
  }
}

.navbar-brand {
  padding: 12px 15px;
  cursor: default;
}

.with-secondary-sidebar .sidebar-background {
  width: 410px;
}

.sidebar {
  @media print {
    display: none;
  }
  width: 315px;
  color: #636363;

  > div {
    position: relative;
    height: 100%;
  }

  &.primary-sidebar {
    width: 185px;

    .label {
      border-radius: 15px;
      padding: .1em .5em;
      font-size: 13px;
      top: -1px;
      position: relative;
    }

    .scroll-container {
      > ul.nav:last-child {
        margin-bottom: 75px;
      }
    }
  }

  .nav {

    notification-badge > .label {
      &:extend(.sidebar.primary-sidebar .label);
      background-color: @admin-review-indicator-color;
      font-size: 13px;
      vertical-align: middle;
    }

    li {
      position: relative;
      display: block;

      a {
        color: @nav-pane-font-color;
        position: relative;
        display: block;
        padding: 8px 5px 8px 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-decoration: none;
        fill: @nav-pane-font-color;

        &.color-font{
          color:#225bb7;
        }
        &.no-padding{
          padding-top: unset;
          padding-bottom: unset;
        }
        &.move-child-up {
          margin-bottom: -19px;
        }
        .collapsed-nav-icon:not(.no-toggle) {
          display: none;
        }
        .advanced-search-icon{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: inherit;
        }

        &#notificationsDisplay {
          font-size: 15px;
          padding-left: 14px;

          i {
            &:extend(.sidebar .nav li a.L1 i);
          }

          .collapsed-nav-icon {
            width: 100%;

            span.nav-title {
              padding-left: 1px;
              padding-right: 2px;
            }
          }
        }

        &.L1 {
          font-size: 15px;
          text-transform: uppercase;

          i {
            display: inline-block;
            width: 16px;
          }

          > span {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: top;
          }
        }

        &.L2 {
          padding-left: 34px;
          font-weight: bold;
          white-space: initial;

          i {
            display: inline-block;
            width: 15px;
          }

          span {
            display: inline-block;
            vertical-align: top;
            width: 85%;
          }
        }

        &.L3 {
          padding-left: 51px;

          &.person-list-header {
            background-clip: content-box;
            background-color: @nav-pane-l4-header-color;
            border-radius: 2px;
            font-size: 11px;
            margin: 6px 5px 4px 51px;
            padding: 0px 0px 0px 0px;

            i {
              display: inline-block;
              width: 14px;
              padding-left: 2px;
              padding-right: 0px;
            }

            span {
              padding-left: 3px;
              vertical-align: top;
            }

            &:hover {
              &:extend(.sidebar .nav li a:hover);
            }
          }
        }

        &.L4 {
          padding: 2px 5px 2px 53px;

          .people-list-item-icon {
            width: 15px;
          }
        }

        &:hover {
          background: #808685;
          color: white;
          fill: white;
          text-decoration: none;
        }

        .fa {
          padding-right: 5px;
          &.fa-spin {
            padding-right: 0px;
          }
        }
        a{
          color:#225bb7;
        }
      }

      &.active > a {
        background: #606665;
        color: white;
        fill: white;
        text-decoration: none;
      }
    }
    li.header ul {
      padding-left: 0;
      list-style: none;
    }

  }

  .subnav {
    li:last-of-type {
      a {
        padding-bottom: 0px;
      }

      a:after {
        border-bottom: 1px solid @nav-pane-l4-header-color;
        content: '';
        display: block;
        padding-top: 6px;
      }
    }
  }

  .hide-primary & {
    .university-logo {
      display: none;
    }

    .primary-sidebar-background, &.primary-sidebar, .primary-sidebar-footer {
      width: 95px;
    }

    &.primary-sidebar {

      li {
        a {
          display: flex;
          height: 25px;
          justify-content: flex-end;
          margin: 4px 0 4px 0;
          padding: 0px;
          text-align: center;

          &#notificationsDisplay {
            border-bottom: 1px solid #ccc;
            height: 42px;
            margin-bottom: 0px;
            margin-top: 4px;
            padding: 0px;

            span.collapsed-nav-icon {
              width: 50%;
            }

            notification-badge {
              position: absolute;
              padding-left: 1.1em;
              padding-bottom: 1.5em;
            }

            span.nav-title {
              display: none;
            }
          }

          &.L1 {
            height: 40px;

            &:hover .fa-inverse {
              color: @collapsed-nav-pane-L1-icon-color;
            }

            .collapsed-nav-icon .fa-stack {
              font-size: 0.7em;

              i {
                width: inherit;
              }
            }
          }

          &.L2:not(#openOspProposals) {
            margin-top: 15px;
          }

          > span {
            display: none;
          }

          > i {
            width: 50%;
            height: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          span.collapsed-nav-icon {
            &:extend(.hide-primary .sidebar.primary-sidebar li a > i);
            color: white;
            fill: white;

            &.vertical-stack-icon {
              flex-direction: column;
              padding-top: 1px;
            }

            &.horizontal-stack-icon {
              justify-content: initial;

              .horizontal-stack-icon-left, .horizontal-stack-icon-right {
                width: initial;
                margin-left: 6%;
                padding-right: 0%;
                background: none;
              }

              .horizontal-stack-icon-left {
                margin-left: 12%;
                font-size: 11px;
              }

              .center-content {
                margin-left: 30%;
              }
              .overlap-icon {
                margin-left: -23%;
                margin-top: 22%;
              }
            }

            i {
              padding: 0;
            }

            > i.fa-ellipsis-h:not(.vertical-stack-bottom-icon) {
              margin-top: 2px;
            }

            > svg {
              width: 18px;
              height: 18px;
            }

            .vertical-stack-top-icon {
              height: 8px;
              font-size: 0.9em;
            }

            .vertical-stack-bottom-icon {
              height: 7px;
            }
          }

          &.person-list-header {
            height: 12px;
            margin-left: 61%;
            margin-right: 11%;

            > i {
              &:extend(.hide-primary .sidebar.primary-sidebar li a > i);
              width: 100%;
            }
          }
        }
      }

      li:not(.active) > a:not(:hover) {
        .collapsed-nav-icon {
          background: #878E9880;
        }

        &.L1 > .collapsed-nav-icon {
          background: inherit;
          color: @collapsed-nav-pane-L1-icon-color;
          fill: @collapsed-nav-pane-L1-icon-color;
        }

        &#notificationsDisplay > .collapsed-nav-icon {
          background: inherit;
          color: inherit;
          fill: inherit;
        }
      }
    }

  }

}

.primary-sidebar-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 180px;
  padding: 4px;
  font-size: .8em;
  background-color: #e0e0e0;
  border-right: 1px solid #c0c0c0;

  .pull-right a {
    display: block;
    padding: 0px 4px;
  }
}

.sidebar {
  transition: .75s width ease;
}

.hide-secondary {
  .secondary-sidebar {
    width: 0px;
    overflow: hidden;

    .filter-display {
      width: 0px;
      opacity: 0;
    }
    .scroll-container {
      width: 0%;
    }
    .summary-footer {
      display: none;
    }
  }

}

.secondary-sidebar .scroll-container {
  background-color: #fbfbfb;
  border-right: 1px solid #eee;
  transition: .75s ease;
  .item {

    a {
      border-left: 3px solid transparent;
    }
    .col-md-10 {
      padding-right: 0px;
    }
  }
}

.centered-overlay-parent {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
  height: 100%;
  width: 100%;
}

.centered-overlay {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.content-logo {
  width: 60%;
  font-size: 20px;
  text-align: center;
  color: #999;
}

#product-details {
  font-size: .5em;
}

.content-header {
  -webkit-transform: translateZ(0);
  border-bottom: 1px solid #dddddd;
  background-color: #fafafa;
  z-index: 10;
  padding: 0px 15px;

  &:before, &:after {
    display: table;
    content: "";
    line-height: 0;
    clear: both;
  }

  .title-info h4 {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .header-footer {
    margin-top: 10px;
    .alert {
      margin-bottom: 0;
      padding: 6px 8px;
      font-size: 14px;

      .checkbox {
        margin-top: 5px;
        margin-bottom: 5px;

        label {
          min-height: initial;
        }
      }
    }
  }
}

.alert.alert-large-text {
  font-size: 15px;
}

.content-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 30px;
}

table tr.unread {
  border-left: 3px solid #006687;
  font-weight: bold;

  label {
    font-weight: bold;
  }
}

table tr.completed td, table tr.completed td label {
  text-decoration: line-through;
}

.nav {
  @media print {
    display: none;
  }
}

.initiator-modal-window .modal-dialog {
  width: 1000px;
}

.nav.toc li.active > a {
  font-weight: bold;
}

ul.nav.toc {
  -webkit-transform: translateZ(0);
  position: fixed;
  list-style: none;
  font-size: 11px;

  .toc-collapsible-item-icon {
    margin-left: -20px;
    margin-right: 2px;
    padding: 5px;
    width: 17px;
    text-align: center;
  }

  a {
    padding: 5px;
    display: block;
    color: gray;

    &:focus {
      background-color: transparent;
    }

    &:hover {
      text-decoration: none;
      background-color: #eee;
    }

    &.disabled-panel {
      color: #ccc;
    }
  }

  li ul {
    padding-left: 20px;
    list-style: none;
  }

  li {
    margin-top: -5px;
  }
}

.popover-content.tabled {
  padding: 0px;
  background-color: white;
  color: black;

  table.table {
    margin-bottom: 0px;
  }
}

.notificationCard table tr {
  border-left: 3px solid transparent;

  &.unread {
    border-left: #006687;
  }
}

.checklist {
  > pathed-checklist-item, > new-checklist-item {
    border-bottom: 1px solid #ddd;
    display: block;
  }

  > pathed-checklist-item .checklistItem, > new-checklist-item .checklistItem {
    display: block;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  &.noDivider {
    > pathed-checklist-item, > new-checklist-item {
      border-bottom: none;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-top: 5px;
      padding-bottom: 5px;

      &:first-child {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }

  > :last-child {
    border-bottom: 0px;
    margin-bottom: 15px;
  }
}

pathed-checklist-item {

  .enabled:hover {
    background-color: @row-hover-bg;
  }

  .enabled:hover .checklistItemActions, .checklistItemActions.open {
    opacity: 1;
  }
}

.checklistItem {
  &.disabled {
    color: #909090;

    .fa.fa-toggle-off {
      color: black;
    }
  }

  .actions {
    .fa {
      font-size: 20px;
      margin-right: 5px;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

    }

    &.disabled .fa {
      color: #ccc;
      cursor: default;
    }
  }

  fa.toggle {
    margin-right: 15px;
    font-size: 22px;
  }

  .fa-square-o, .fa-check-square-o {
    width: 20px;
    text-align: left;
  }

  .checklistItemActions {
    margin-left: -90px;
  }

  .item-notes {
    margin-left: 30px;
    word-wrap: break-word;
  }

  .item-details {
    margin-left: 40px;
  }
}

.checklistItemActions {
  i#rightCaret{
    display: inline-block;
  }
  i#downCaret{
    display: none;
  }

  &.open {
    i#rightCaret{
      display: none;
    }
    i#downCaret{
      display: inline-block;
    }
  }
}

.document-actions {
  i#rightCaret{
    display: inline-block;
  }
  i#downCaret{
    display: none;
  }

  &.open {
    i#rightCaret {
      display: none;
    }
    i#downCaret {
      display: inline-block;
    }
  }
}

.document-name-wrap {
  word-break: break-all;
}

.rename-file-type {
  margin-top: 10px;
  margin-left: -20px;
}

.teamMemberMenu {
  i#rightCaret{
    display: inline-block;
  }
  i#downCaret{
    display: none;
  }

  &.open {
    i#rightCaret {
      display: none;
    }
    i#downCaret {
      display: inline-block;
    }
  }
}

.actions-caret-right {
  font-size: 1.5em;
  vertical-align: bottom;
  padding-left: 3px;
}

.actions-caret-down {
  font-size: 1.5em;
  vertical-align: sub;
}

.file-actions-caret-right {
  font-size: 1.7em;
  vertical-align: sub;
  padding-left: 7px;
  padding-right: 1px;
}

.file-actions-caret-down {
  font-size: 1.8em;
  vertical-align: sub;
  padding-left: 3px;
}

.processor-comments-button {
  margin-left: 50px;
  margin-top: -20px;
}

.comments-action-button {
  position: relative;
  float: left;
}

.fa {
  position: relative;

  input[type='checkbox'] {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100%;
    opacity: 0;

    &:not(:disabled) {
      cursor: pointer;
    }
  }
}

vt-upload-file {
  .document-confirmation-list {
    margin-top: 5px;
    margin-bottom: 0px;
    word-break: break-all;
  }
  .dropzone {
    position: relative;
  }
  .upload-button {
    width: 110px;
  }
}

.row .dropzone {
  padding: 5px 0px 5px 5px;
  border: 1px dashed #ccc;
  margin-right: 15px;
}

.dropzone {
  padding: 3em;

  .dropzone-overlay {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border: 3px dashed #fff;
    background-color: rgba(0, 86, 132, .9);
    transition: opacity 250ms;
    z-index: -100;
    opacity: 0;
    color: white;
    text-align: center;
    font-size: 1.5em;

    h2, h3, h4 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      left: 50%;
      margin: 0px;
    }

  }

  &.dropzone-hover .dropzone-overlay {
    opacity: 1;
    z-index: 10000;
  }

  .file-drop-block {
    padding: 1em 3em 1em 3em;
    background: #E7EAE13D;
    border: 2px dashed #C6CCB2;
    margin-bottom: 3em;

    button {
      padding-left: 3em;
      padding-right: 3em;
      margin-right: 1.5em;
    }

    span {
      font-family: 'roboto-medium';
      font-size: initial;
      color: #C8C9CA;
    }
  }

  .title-color {
    i {
      color: #A3A699;
    }

    color: #4A4A4A;
    font-family: 'roboto';
    font-size: 14px;
  }

  .help-text {
    color: #486F86;
    font-family: 'roboto-condensed';
  }

  ul {
    list-style-type: none;
  }

  .button-style {
    border: 1px solid #9B9B9B;
    font-family: 'roboto-condensed';
    font-size: 14px;
    color: #3A5B6E;
    background-color: #E7EAE13D;
  }

  .icon-style {
    height: 39px;
    line-height: 39px;

    i {
      font-size: medium;
    }
  }

  .unset-margin {
    margin-bottom: unset;
  }

  .input-padding {
    padding-top: 10px;
  }

  .file-details {
    border: 1px solid #C6CCB2;
    padding-left: 1em;
    padding-bottom:1em ;
    margin-bottom: 15px;
    background-color: #eaeaea1f;

    .close-button {
      margin-right: 3px;
    }
  }

  .align-height {
    line-height: 40px;
  }

  .table-style {
    td {
      padding-left: 15px;
      font-family: 'roboto';
    }

    margin-bottom: 2em;
  }

  #selected-files-list {
    padding: 0;
  }

}


.document-table {
  margin-left: 2px;
}

.document-upload {
  margin-left: 2px;
}

.document-table > .row {
  padding-top: 5px;
  padding-bottom: 5px;
}

tabtastic-pane {
  border: 1px solid #ccc;
  display: block;
  border-top: none;
  padding: 15px;
}

#subcontractor tabtastic-container > ul.nav-tabs > li > a {
  max-width: 125px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.error-dialog {
  .modal-header {
    background-color: #d00;
  }
  .modal-footer button {
    span {
      text-decoration: underline;
    }
  }
}

.modal-footer .options {
  float: left;
  input {
    margin-right: 1ex;
  }
}

.modal-footer .download-all-files-link-font {
  font-size: 1.2em;
}

.files-deleted .dimmable {
  text-decoration: line-through;
}

choice-selection {
  display: block;
  margin-bottom: 10px;

  span {
    display: inline-block;
  }
}

.clickToEdit:hover {
  cursor: pointer;
  background-color: #eaeaea;
}

.proposal-editor-status-indicator {
  margin-top: 8px;

  .status-label {
    border-radius: 0.1em;
    vertical-align: middle;
    margin-top: -4px;
    min-height: 2em;
    max-height: 3.5em;
    line-height: 1.5;
    font-size: 13px;
    max-width: 100px;
    background-color: #FF5455;
    margin-right: 1em;
  }

  .pending-award {
    background-color: #339C37;
  }

  .pi-verification-pending {
    background-color: orange;
  }

  .pi-verification-bypassed{
    background-color: grey;
  }

  .pi-verified {
    background-color: deepskyblue;
  }
}

.align-status{
  margin-top: 9px;
  margin-left: 25px;
  display: flex;
}

.status-indicator-icon {
  font-size: .6em;


  &.editor-status {
    font-size: .75em;
    padding-top: 4px;
    padding-right: 5px;
  }

  &.status-PREPARING {
    color: @status-indicator-color-preparing;
  }
  &.status-ROUTING {
    color: @status-indicator-color-in-routing;
  }
  &.status-APPROVED {
    color: @status-indicator-color-approved;
  }
  &.status-SUBMITTED {
    color: @status-indicator-color-submitted;
  }
  &.status-REVIEWED {
    color: @status-indicator-color-reviewed;
  }
  &.status-PENDING_DECISION {
    color: @status-indicator-color-pending-decision;
  }
  &.status-CLOSED {
    color: @status-indicator-color-closed;
  }
  &.status-REVISING {
    color: @status-indicator-color-revising;
  }
}

.right-inner-addon {
  position: relative;
  input {
    padding-right: 30px;
  }
  i {
    position: absolute;
    right: 0px;
    padding: 10px 12px;
    pointer-events: none;
    z-index: 10;
  }
}

document-manager {
  .document-row {

    padding-left: 3px;
    margin-left: 2px;
    margin-top: 3px;

    .document-actions {
      display: inline-block;
    }

    &:hover {
      background-color: @row-hover-bg;
    }

    &:hover .document-actions, .document-actions.open {
      display: inline-block;
    }
  }
}

.document-details {
  margin-bottom: 10px;
  margin-right: 40px;
  word-break: break-word;
}

.doc-creator-name {
  word-break: break-word;
  padding-left: 9px;
  padding-right: 0px;
  padding-bottom: 10px;
}

.doc-time-stamp {
  padding-right: 0;
}

.selectize-input {

  fieldset[disabled] & {
    background-color: #eeeeee !important;
    cursor: not-allowed !important;
  }
}

/* Typeahead Bootstrap Styling */
span.twitter-typeahead {
  width: 100%;

  .tt-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
  }

  input {
    background-color: #fff !important;

    &[disabled], fieldset[disabled] & {
      background-color: #eeeeee !important;
    }
  }

  .tt-suggestion {
    > p {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.42857143;
      color: #333333;
      white-space: nowrap;
      margin-bottom: 0px;

      &:hover, &:focus {
        color: #ffffff;
        text-decoration: none;
        outline: 0;
        background-color: @brand-primary;
      }
    }
    &.tt-cursor > p {
      color: #ffffff;
      background-color: @brand-primary;
    }
  }
}

.inline-typeahead .twitter-typeahead {
  width: auto;
}

.input-group span.twitter-typeahead {
  display: block !important;

  .tt-dropdown-menu {
    top: 32px !important;
  }
}

.input-group.input-group-lg span.twitter-typeahead .tt-dropdown-menu {
  top: 44px !important;
}

.input-group.input-group-sm span.twitter-typeahead .tt-dropdown-menu {
  top: 28px !important;
}

/** End typeahead.css themeing */

.suggestion-indent {
  margin-left: 6px;
}

.suggestion-qualifier {
  color: @gray-light;
}

.suggestion-building {
  &:before {
    content: "\f19c";
    font: normal normal normal 12px/1 FontAwesome;
    font-size: 10px;
    margin-right: 4px;
    margin-left: -10px;
    margin-bottom: 2px;
  }
}

.timepicker.dropdown-menu {
  padding: 0 4px;
  border-radius: 5px;
}

.timepicker.dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 6px;
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #ddd;
}

.timepicker.dropdown-menu button {
  outline: none;
  border: 0px;
  font-size: 12px;
}

.timepicker.dropdown-menu tbody button {
  padding: 6px;
}

.timepicker.dropdown-menu thead button, .timepicker.dropdown-menu tfoot button {
  padding: 10px;
}

vt-date-with-timepicker .input-daterange .input-group-addon:first-child {
  border-left-width: 1px;
}

vt-date-with-timepicker .input-daterange .input-group-addon:last-child {
  border-right-width: 1px;
}

vt-date-with-timepicker .ie-select-size {
  height: 28px;
  width: 50px !important;
  font-size: 12px;
  padding: 0px;
}

.input-daterange .input-group-addon.clickable {
  cursor: pointer;
}

.badge.outstandingTasks {
  background-color: #79936C;
}

.form-group.right-align-errors {

  .valdr-message.ng-invalid, div[ng-messages] {
    text-align: right;
    margin-right: 15px;
  }
}

.valdr-form-group.inline-errors {

  .valdr-message.ng-invalid, div[ng-messages] {
    display: inline;
  }
}

.fa-stack .fa {
  position: absolute;
}

.fa-sm {
  font-size: 0.63em;
}

vt-proposal-status-indicator {
  .fa-stack {
    font-size: .5em;
  }

  .submitted .fa-stack-1x {
    top: 2px;
  }
}

vt-people-search i.fa-refresh {
  top: 3px;
}

.cost-share-document-label {
  text-align: right;
}

.control-label-sm-padding {
  padding: 2px !important;
}

.btn-sm-padding {
  padding: 2px !important;
}

div.context-menu {
  position: fixed;
  z-index: 1000;
}

.popover {
  background-color: #fafafa;
  color: #333;
  border-color: #ddd;
}

#activity-log-container {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;

  .item {
    border-bottom: 1px solid #ccc;
    margin: 5px 0px;
    padding-bottom: 5px;
  }

  p {
    margin-bottom: 3px;
  }

  .old-value {
    color: #999;
  }
}

#alternateNamesPopover ul {
  list-style: none;
}

#sponsorAlternateNames .alternateNameMenu {
  opacity: 0;
}

#sponsorAlternateNames .alternateName {
  :hover .alternateNameMenu, .alternateNameMenu.open {
    opacity: 1;
  }
}

vt-proposal-team table#teamMembers {
  margin-bottom: 0
}

#teamMembers .teamMemberMenu {
  opacity: unset;
}

#teamMembers .teamMember {
  :hover .teamMemberMenu, .teamMemberMenu.open {
    &:not(.teamMemberMenuDisabled) {
      opacity: 1;
    }
  }
}

#supportStaff .staffMemberMenu {
  opacity: 0;
}

#supportStaff .staffMember {
  :hover .staffMemberMenu, .staffMemberMenu.open {
    &:not(.staffMemberMenuDisabled) {
      opacity: 1;
    }
  }
}
// Diary styling
existing-checklist-item {
  diary {
    display: block;
    background-color: #f2f2f2;
    margin: 35px -50px 5px 60px;
  }

  .yes-no-supplementary {
    margin-top: 10px;
  }

  .yes-no-diary diary {
    margin-top: 5px;
    margin-left: 0px;
  }

  document-manager diary {
    margin-bottom: 5px;
  }

  .row:hover .diary-topic {
    background-color: #ddd;
  }
  .row:hover .diary {
    background-color: #ddd;
  }
}

.document-row-diary {
  margin-left: 33px;
  margin-top: -95px;
}

.solicitationDocuments {
  margin-left: -18px;
}

.budget-documents-section {
  margin-top: -20px;
}

.budget-documents {
  margin-left: -20px;
}

.budget-documents-details {
  margin-left: -15px;
  margin-bottom: 10px
}

.budget-requirements-text {
  margin-top: -8px;
}

.vt-document-upload {
margin-left: 10px;
}

.actions-button {
background-color: white;
color: #2C4858;
border-color: #c4c4c4;
font-weight: 600;
letter-spacing: 1.25px;
padding-left: 10px;
padding-right: 10px;
}
.actions-button:hover {
background-color: #2C4858;
color: white;
border-color:#2C4858;
}

.actions-button:disabled {
opacity: 0.6;
cursor: not-allowed;
}
.actions-button:active {
background-color: #2C4858;
color: white;
border-color:#2C4858;
}

.file-actions-button {
background-color: white;
color: #2C4858;
border-color: #c4c4c4;
font-weight: 700;
word-spacing: 2.5px;
padding-left: 10px;
padding-right: 10px;
padding-bottom: 4px;
padding-top: 4px;
}
.file-actions-button:hover {
background-color: #2C4858;
color: white;
border-color:#2C4858;
}
.file-actions-button:disabled {
opacity: 0.6;
cursor: not-allowed;
}
.file-actions-button:active {
background-color: #2C4858;
color: white;
border-color:#2C4858;
}

.accept-budget-file-button {
background-color: #006400;
color: white;
border-color: #006400;
word-spacing: 2.5px;
font-size: 15px;
padding-left: 35px;
padding-right: 35px;
padding-bottom: 3px;
padding-top: 3px;
font-weight: 700;
margin-right: 20px;
}
.accept-budget-file-button:hover {
background-color: white;
color: #106404;
border-color:#106404;
}
.accept-budget-file-button:disabled {
opacity: 0.6;
cursor: not-allowed;
background-color: lightslategray;
color: black;
border-color: #c4c4c4;
padding-bottom: 5px;
padding-top: 5px;
font-size: 12px;
}

.disable-accept-budget-button {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.disable-accept-budget-button:hover {
background-color: white;
color: black;
border-color:#c4c4c4;
}

diary-button .popover {
max-width: none !important;
}

#diary-container {
max-height: 500px;
width: 350px !important;
max-width: 350px !important;
overflow-x: auto;
overflow-y: scroll;
}

#diary-popover {
h4 {
  display: inline-block !important;
}

button {
  margin-top: 8px;
}
}

.diary-topic {

&.inline {
  background-color: #f2f2f2;
  padding: 5px 8px;
  width: 100%;
}

.diary-middle-entry {
  background-color: #f2f2f2;
  padding: 5px 8px;
}

.diary-topic-label {
  margin-bottom: 8px;
  word-break: break-all;
}

.diary-more-link {
  border-bottom: 1px solid #bbb;
  padding-bottom: 2px;
  margin-bottom: 5px;

  a {
    display: block;
  }
}

.diary-entry-author {
  color: #333333;
  margin-right: 8px;
}

.diary-entry-date {
  color: #999;
  margin-right: 8px;
}

.diary-entry-note {
  color: #333333;

  // reflect whitespace in all inner elements of the diary entry
  p {
    white-space: pre-wrap;
  }
}

.diary-topic-label {
  padding: 0px 0px 8px 0px;
  word-break: break-all;
}

.diary-topic-footer {
  margin-top: 8px;
  margin-bottom: 8px;
  &.inpopover {
    &.not-replying {
      background-color: #f2f2f2;
    }
    padding: 5px 5px 5px 0px;
    margin-right: 8px;
  }
}

.diary-add-entry {
  margin-top: 8px;
  padding-top: 12px;
  border-top: 1px solid #bbb;
}
}

#sync-channel-status {
padding: 5px;
margin: 7px 15px;
border: 2px solid orange;
}

#application-links-container {
  border: none;
  font-size: 18px;
  display: flex;
  padding: 5px 15px 5px 38px;
  margin-top: 7px;
  font-weight: 500;

  .link-selection-indicator {
    border-bottom: 4px solid white;
    top: 19px;
    left: 184px;
    position: absolute;
    width: 106px;
    box-shadow: 0 10px 15px rgba(0%, 0%, 0%, 0.2);
  }

  a {
    letter-spacing: 1px;
    color: #c3dae7;
    margin-left: 50px;

    &:hover, &:focus {
      color: white;
      text-decoration: none;
    }
  }
}

.notification-popover {
max-width: 400px;
width: 400px;
left: 10px !important;

.empty-notifications {
  padding: 15px;
}

.sort-by-header {
  z-index: 1000;
  position: fixed;
  width: 379px;
  padding: 10px;
  text-align: left;

  color: #ffffff;
  background-color: @brand-callout;

  .work, .date {
    display: inline-block;
    padding-left: 15px;
  }

  .clear {
    float: right;
    display: inline-block;
  }

  a {
    color: #ffffff;
  }

  a.active {
    text-decoration: underline;
  }
}

.messages-container {
  padding-top: 35px;
}

.message-container {

  .header-24-hours, .header-7-days, .header-remaining, .header-work-item {
    background-color: #fff;
    border-top: 4px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    color: @brand-callout;

    .clear {
      float: right;

      a {
        display: inline-block;
        color: @brand-callout;
      }

    }
  }

  .selected {
    background-color: @gray;
    color: #ffffff;
  }

}

.arrow {
  left: 10px !important;
}

.notifications {
  border-top: 1px solid #aaa;
  border-left: 5px solid #aaa;
  border-bottom: 1px solid #aaa;
}

.popover-inner, .popover-content {
  max-width: 400px;
  padding: 0px;
}

.message {
  padding: 10px 15px;
  border-bottom: 1px solid #ccc;
  transition: background-color .05s linear;
  cursor: pointer;
  background-color: #fff;
  word-break: break-word;

  .message-text {
    border-left: 3px solid transparent;

    &.unread {
      border-left-color: #006687;
      padding-left: 5px;

      &:hover {
        border-left-color: #000000;
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #eee;
    color: #000;
  }

  p {
    margin-bottom: 0px;
  }

  .hover-only {
    display: none;
  }

  &:hover {
    .hover-only {
      display: inline-block;
    }
  }

}

#notification-display {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

}

.save-errors-list {
margin-top: 15px;

li {
  margin-top: 8px;
}
}

.save-errors-popover {
min-height: 100px;
}

span.link {
cursor: pointer;
color: @link-color;
text-decoration: none;

&:hover {
  color: @link-hover-color;
  text-decoration: @link-hover-decoration;
}
}

initiator-wizard {
h4 .btn.revise {
  position: relative;
  top: -7px;
}
}

a.filename {
overflow-wrap: break-word;
word-wrap: break-word;
}

.document-actions {
.dropdown-menu > li > a {
  padding: 6px 15px;
}

.dropdown-header {
  padding-left: 8px;
  padding-right: 8px;
  word-wrap: break-word !important;
  white-space: normal !important;
}

.divider {
  width: 25px;
  margin: 8px;
}
}

.document-deleted {
white-space: nowrap;
padding: 0px 3px 0px 3px !important;
margin-top: 0px;
margin-bottom: 1px;
display: inline-block;
background-color: @brand-danger;
color: #fff;
&:before {
  color: #fff;
  content: "\f1f8\00a0";
    font: normal normal normal 14px/1 FontAwesome;
  }
}

.document-not-accepted, .document-not-approved, .document-pending, .document-include-submission {
  white-space: nowrap;
  padding: 0px 3px 0px 3px !important;
  margin-top: 10px;
  margin-bottom: 1px;
  display: inline-block;
  background-color: @brand-warning;
  color: #fff;
  &:before {
    color: #fff;
    content: "\f003\00a0";
    font: normal normal normal 14px/1 FontAwesome;
  }
}

.document-pending {
  &:before {
    content: "\f128\00a0";
  }
}

.document-revision {
  background-color: @admin-review-indicator-color;
}

.document-status-in-modal {
  display: block;
  white-space: normal;
  max-width: 120px;
}

.document-include-submission {
  background-color: @brand-success;
}

.document-requires-signature {
  white-space: nowrap;
  padding: 0px 10px 0px 3px !important;
  margin-top: 10px;
  display: inline-block;
  background-color: @brand-primary;
  color: #fff;
  &:before {
    color: #fff;
    content: "\f040";
    padding-right: 3px;
    font: normal normal normal 14px/1 FontAwesome;
  }
}

.entity-label-display {
  // Default label display:
  white-space: nowrap;
  padding: 0 3px 0 3px !important;
  margin-top: 10px;
  margin-bottom: 1px;
  display: inline-block;
  background-color: @gray;
  color: #fff;

  // Default icon for a label
  &:before {
    content: "\f02e";
    font: normal normal normal 11px/1 FontAwesome;
  }

  &.award {
    background-color: @label-award-file-background-color;
    &:before {
      content: @label-award-file-icon;
    }
  }

  &.rfp {
    background-color: @label-rfp-background-color;
    &:before {
      content: @label-rfp-icon;
    }
  }

  &.budget {
    background-color: @label-budget-background-color;
    &:before {
      content: @label-budget-icon;
    }
  }

  &.proposal {
    background-color: @label-proposal-background-color;
    &:before {
      content: @label-proposal-icon;
    }
  }

  &.overhead-distribution {
    background-color: @label-form-c-background-color;
    &:before {
      content: @label-form-c-icon;
      background-color: white;
      color: @label-form-c-background-color;
      border-radius: 10px;
      font-family: 'Roboto-Regular', sans-serif;
      display: inline-flex;
      width: 12px;
      height: 13px;
      align-items: center;
      justify-content: center;
    }
  }

  &.subcontractor-sow, &.subcontractor-loc, &.subcontractor-budget-justification, &.subcontractor-data-form, &.subcontractor-budget {
    background-color: @label-subcontractor-background-color;
    &:before {
      content: @label-subcontractor-icon;
    }
  }
}

.document-accepted-approved {
  padding: 0px 3px 0px 3px !important;
  margin-top: 10px;
  margin-bottom: 1px;
  display: inline-block;
  background-color: @brand-success !important;
  color: #fff;
}

a.color-tag-picker-color {
  text-decoration: none;
  display: inline-block;
  margin-right: 5px;
  width: 16px;
  height: 16px;

  span {
    padding-left: 3px;
    position: relative;
    top: 2px;
  }
}

.color-tag {
  display: inline-block;
  width: 16px;
  height: 4px;
  margin-right: 2px;
}

.color-tag.large {
  height: 16px;
  margin-right: 5px;
}

.color-tag-RED {
  background: #f45a58 url("/images/16x4_diagonal_left.png");

  &.large {
    background-image: url("/images/16x16_diagonal_left.png");
  }
}

.color-tag-ORANGE {
  background: #ff9937 url("/images/16x4_dots.png");

  &.large {
    background-image: url("/images/16x16_dots.png");
  }
}

.color-tag-YELLOW {
  background: #f1d104 url("/images/16x4_diagonal_right.png");

  &.large {
    background-image: url("/images/16x16_diagonal_right.png");
  }
}

.color-tag-GREEN {
  background: #62c014 url("/images/16x4_zigzag.png");

  &.large {
    background-image: url("/images/16x16_zigzag.png");
  }
}

.color-tag-BLUE {
  background: #40dbff url("/images/16x4_vertical.png");

  &.large {
    background-image: url("/images/16x16_vertical.png");
  }
}

.color-tag-PURPLE {
  background: #c773cf url("/images/16x4_horizontal.png");

  &.large {
    background-image: url("/images/16x16_horizontal.png");
  }
}

.textarea-counter {
  color: @brand-success;
}

.secondary-label {
  color: #222222;
  font-size: 10px;
  margin-bottom: 1px;
}

#hidden-approvals-control {
  color: #222222;
  font-size: 10px;
  margin-left: 5px;
  margin-top: 2px;
  span {
    vertical-align: 3px;
    margin-left: 2px;
  }
}

.floating-label-wrapper {
  position: relative;
  margin-top: 15px;
}

.floating-label {
  color: #7E7E7E;
  font-size: 14px;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 10px;
  transition: 0.2s ease all;
  opacity: 0;
}

.form-control:focus ~ .floating-label,
.form-control:not(.empty) ~ .floating-label {
  top: -15px;
  left: 0;
  font-size: 11px;
  opacity: 1;
}

.form-control.empty ~ .floating-label {
  opacity: 1;
}

.form-control-static {
  @media print {
    display: inline-flex;
  }
}

other-direct-cost-share-item, third-party-cost-share-item {
  display: block;
  background-color: #fcfcfc;
  padding: 15px;

  &:nth-of-type(odd) {
    background-color: #f5f5f5;
  }
}

.other-direct-cost-share-item-read-only, .third-party-cost-share-item-read-only {
  display: block;
  background-color: #fcfcfc;
  padding: 15px;

  &:nth-of-type(even) {
    background-color: #f5f5f5;
  }
}

.personnel-items {
  background: #eee;
  padding: 10px;
}

#thirdPartyContributionCostShare {
  padding: 10px;
}

personnel-cost-share-person {
  display: block;
  background: white;
  padding: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.personnel-cost-share-person-read-only {
  display: block;
  background: white;
  padding: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
}

personnel-cost-share-organization {
  border-bottom: 1px solid #ddd;
  display: block;
  padding-bottom: 1px;
  margin-bottom: 10px;

  &:last-child {
    border-bottom: 0px;
  }

  .input-group-addon {
    padding: 8px 4px;
    font-size: 12px;
  }

  .personnel-cost-share-organization-input-field {
    margin-left: -20px;
  }

}

.action-menu-row {
  .action-menu-wrapper {
    margin-left: -15px;
    display: inline-block;
    i { display: inline-block; }

    &.open {
      display: inline-block;
      i { display: inline-block; }
    }

      .cost-share-actions-button {
        background-color: white;
        color: #2C4858;
        border-color: #c4c4c4;
        font-weight: 600;
        letter-spacing: 1.25px;
        padding-left: 10px;
        padding-right: 10px;
        width: 120%;
        height: 40px;
      }
      .cost-share-actions-button:hover {
        background-color: #2C4858;
        color: white;
        border-color:#2C4858;
      }
      .cost-share-actions-button:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
      .cost-share-actions-button:active {
        background-color: #2C4858;
        color: white;
        border-color:#2C4858;
      }
  }

  .third-party-cost-share-input-field {
    margin-left: -15px;
  }

  .personnel-cost-share-input-field {
    margin-left: -10px;
  }

  &:hover {
    .action-menu-wrapper {
      display: inline-block;
    }
  }
}

.marked-for-deletion {
  * {
    text-decoration: line-through;
  }

  .action-menu-wrapper * {
    text-decoration: none;
  }
}

.duplicated-from {
  color: #000000;
  background-color: #ffffff;
  border: 0px;
  font-size: 1em;
}

.checklist-item-table {
  width: 100%;
}

.checklist-item-table tr td {
  height: 35px;
}

.checklist-info-box {
  border-width: 1px;
  border-color: @brand-info;
  border-style: solid;
  padding: 10px;
}

.checklist-info-box p {
  display: inline;
}

.checklist-info-box-icon {
  color: @brand-info;
}

.app-modal-window .modal-dialog {
  width: 1000px;
}

.fa-disabled {
  color: #aaaaaa;
}

.checklist-table-row-cell-status {
  padding-right: 15px;
}

.checklist-table-row-disabled, .checklist-table-row-disabled a {
  color: silver;
}

approval-view {
  display: block;
  margin-bottom: 15px;

  .approval-path-view {
    border: 2px solid #ccc;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0 15px;

    #proposalDetailsForm & {
      margin-bottom: 0;
    }

    .approvable {
      font-weight: bold;
      color: @brand-callout;

      i.fa {
        color: @brand-callout;
      }
    }

    i.fa {
      color: @text-muted;
    }

    .approval {
      padding: 8px 0;
    }

    .approver-display-toggle {
      cursor: pointer;
    }
  }

  .approver-display {
    background-color: #eee;
    padding-top: 8px;
    margin-top: 5px;
  }
}

#proposalDetailsWorkSummary, #budgetInformation {
  .disabled {
    color: @gray-light;
  }
}

.dropdown-menu-header {
  margin-left: 5px;
  color: #000000;
}

.dropdown-menu {
  width: 200px;
  left: -50px;
}

.dropdown-menu-header {
  margin-left: 5px;
  color: #000000;
}

.dropdown-menu {
  width: 200px;
  left: -50px;
}

.open .dropdown-menu {
  display: block;
}

li.effective-approver {
  list-style-type: none;
}

.ng-toast .ng-toast__list {
  max-width: 400px;

  ol {
    padding-left: 5px;
  }

  ul li p {
    margin-bottom: 0px;
  }

  li ul {
    list-style: none;
    margin: 7px 0px;
  }
}

.rows-striped > .row:nth-of-type(odd) {
  background-color: @gray-lighter;
}

.processor-rows-striped > .row:nth-of-type(even) {
  background-color: @gray-lighter;
  border-top: 2px solid @gray-light;
  border-bottom: 2px solid @gray-light;
  margin-left: 15px;
  margin-right: 15px;
}

.processor-rows-striped > .row:nth-of-type(odd) {
  margin-left: 15px;
  margin-right: 15px;
}

.approval-budget-view {
  margin-bottom: 1em;
}

favorite-toggle {
  display: inline-block;
  cursor: pointer;

  &:hover {
    text-shadow: 0px 0px 3px @brand-info;
  }
}

favorite-indicator {
  display: inline-block;
  padding: 0 0 0 0;

  .fa {
    color: @brand-info;
  }
}

.tabled-rows {
  font-size: 12px;
  margin: 0 15px 15px;

  &.has-header > .row:first-child {
    font-weight: bold;
    border-bottom: 2px solid #ddd;
  }

  .row {
    border-bottom: 1px solid #ddd;

    > div {
      padding: 8px;
    }
  }
}

role-label {
  display: inline-block;
  padding: 3px;
  font-size: 1.1em;
}

.processor-personnel-header {
  background-color: @gray-light;
  font-size: 18px;
  color: #ffffff;
}

.close-proposal-warning {
  background-color: @brand-danger;
  font-size: 18px;
  color: #ffffff;
  padding: 15px;
}

.internal-use-only {
  background-color: @brand-warning;
  color: #ffffff;
  padding: 5px;
}

.subcontractor-options input {
  margin-right: 1ex;
}

.nav-tabs {
  a.tab-disabled:focus, a.tab-disabled:hover, a.tab-disabled {
    text-decoration: line-through;
  }
}

.break-word {
  word-wrap: break-word;
}

.ng-toast .ng-toast__message .alert {
  background-color: #fff;
}

html, body, #pageRoot {
  height: 100%;
}

#pageRoot.flex-container > *, #rootWrapper {
  overflow: hidden;
}

.flex-container {
  display: flex;

  @media print {
    display: inline;
  }
}

.flex-container > * {
  flex: none;
  align-items: stretch;
}

.flex-column {
  flex-direction: column;
}

.flex-auto {
  flex: auto !important;
}

.flex-fill {
  justify-content: flex-end;
}

.full-height {
  height: 100%;

  @media print {
    height: auto;
  }
}

.scrollable {
  overflow: auto !important;
}

.allow-overflow {
  overflow: visible !important;
}

.content-body {
  @media print {
    .col-xs-9 {
      width: 100%;
    }
  }
}

#primary-sidebar-footer {
  height: auto;
}

.sidebar {
  &.primary-sidebar {
    background-color: #f2f2f2;
    border-right: 1px solid #ddd;
  }

  &.secondary-sidebar {
    border-right: 1px solid #eee;
    background-color: #fafafa;
  }
}

.status-bar {
  background-color: #e0e0e0;
  padding: 2px 6px;
  font-size: .8em;
  border-top: 1px solid #d0d0d0;
}

.university-logo {
  padding-top: 10px;
  border-top: 1px solid #ddd;
  opacity: .7;

  img {
    height: 25px;
    margin-bottom: 9px;
  }
}

.summary-header {
  padding: 3px 6px;
  background-color: #e0e0e0;
  border-bottom: 1px solid #d0d0d0;

  .col-md-11, .col-md-1 {
    padding: 0px;
  }

  i {
    padding-top: 1em;
  }

  input {
    border-radius: 10px;
    font-size: 1.2em;
    height: 2.5em;
  }
}

#summary-loading {
  text-align: center;
}

.summary-list {
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
  overflow-x: hidden;

  .summary-item {
    &.active {
      background: #ddd;

      a.inactive-item {
        background: inherit;
      }
    }

    a {
      border-left: 3px solid transparent;;
      padding: 10px 12px 10px 12px;
      border-bottom: 1px solid #ccc;
      display: block;
      cursor: pointer;

      &.unread.status-open {
        border-left: 3px solid #006687;
      }

      &.admin-review {
        border-right: 2px solid @admin-review-indicator-color;
      }
      &.inactive-item{
        background: #EFEFEF;

        #inactive-record{
          font-style: italic;
        }
      }

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;

        .fa-check, .fa-check-square-o{
          font-size: 15px;
          line-height: 20px;
          color: green;
        }

        .fa-clock-o{
          font-size: 15px;
          line-height: 20px;
          color: indianred;
          min-width: 13px;
        }

        .key-date {
          padding: 0 0px 0px 0px;
          text-align: right;
          overflow: initial;

          .fa {
            font-size: .8em;
            color: @icon-summary-item-keydate-color;
            top: -1px;
            width: 17%;
            text-align: left;
            padding-left: 2px;
          }

          .fa-bullseye {
            font-size: .9em;
            top: 0px;
          }

          .fa-minus-circle{
            font-size: 1em;
            top: 0px;
          }

          .sponsor-icon-stack {
            width: 10px;
            margin-bottom: -16px;
            margin-right: -1px;
          }

          .sponsor-icon-circle {
            font-size: 0.75em;
            margin-left: -6.5px;
            margin-top: 3.2px
          }

          .sponsor-icon-usd {
            font-size: 0.47em;;
            margin-top: -8.6px;
            margin-left: -3px;
            color: white;
          }

        }

        .submitting-org, .proposal-label {
          font-size: 12px;
        }

        .sponsor {
          font-weight: bold;
        }

        .identifier {
          padding-left: 0;
          padding-right: 0;
          width: 32%;
          white-space: initial;
        }

        .activity-type {
          white-space: initial;
          padding-left: 5px;
          padding-right: 0px;
          width: 32%;
        }

        .proposal-status {
          &:extend(.summary-list .summary-item a div .sponsor);
          white-space: initial;
          padding-left: 5px;
          padding-right: 1px;
          width: 30.0%;
        }

        .status-indicator-icon-container {
          padding-right: 0px;
          padding-left: 2px;
          margin-top: -1px;
          width: 6%;
        }

        .pi {
          padding-left: 0px;
          padding-right: 5px;
        }
      }

      .col-md-10 {
        padding-right: 0px;
      }
      .col-md-2 {
        padding-left: 4px;
        padding-right: 0px;
        overflow: initial;

        access-icon {
          color: #888888;
          overflow: initial;
        }
      }
      .col-md-1 {
        padding-left: 0px;
        padding-right: 0px;
      }
      .col-md-5 {
        padding-left: 0px;
        padding-right: 0px;
        overflow: initial;
      }

      key-date {
        padding-left: 0px;
        padding-right: 0px;
        overflow: initial;
      }
    }
  }
}

.simple-tooltip, .summary-pane-tooltip, .nav-pane-tooltip {
  > .tooltip-inner {
    background-color: black;
    color: white;
    text-align: left;
    opacity: 1;
    font-size: 11px;
    -webkit-box-shadow: 0px 1px 1px 0px black;
    -moz-box-shadow: 0px 1px 1px 0px black;
    box-shadow: 0px 1px 1px 0px black;
    padding: 7px;
    border-radius: 4px;
  }
}

#summary-loading {
  text-align: center;
}

.safari-only {
  display: none;
}

.safari {
  .safari-only {
    display: inherit;
  }
  .content-body {
    margin-bottom: 45px;
  }
}

.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;

}

.preferences-unsubscribe {
  .checkbox-control {
    padding-top: 1.25ex;

    span {
      padding-left: 0.5em;
    }

    i {
      padding-right: 0.5em;
    }

  }

}

.notification-label {
  border-radius: 15px;
  padding: .1em .5em;
  font-size: 13px;
  top: -1px;
  position: relative;
  background-color: @label-warning-bg;
  color: #ffffff;
}

.history {
  width: 600px;
  max-width: 600px;
}

#history-table {
  width: 100%;
  background-color: #fcfcfc;

  &:nth-of-type(odd) {
    background-color: @gray-lighter;
  }

  .current {
    font-weight: bold;
  }
}

.release-notes-icon {
  background-color: @label-warning-bg;
  border-radius: 15px;
  padding: 5px;
}

initiator-getting-started-step {
  display: block;

  .option {
    .panel {
      transition: box-shadow 0.3s ease-in-out;

      &.selected, &:hover {
        box-shadow: 3px 3px 1em rgba(0, 0, 0, 0.5);
      }

    }
  }
}

supplement-grant-option {
  h4.advancing {
    margin-top: 24px;
    margin-bottom: 27.5px;
  }
  h4.not-advancing {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

proposal-lookup {
  display: block;
}

.approval-approved {
  background-color: @brand-success;
  width: 170px;
}

.approval-disapproved {
  background-color: @brand-danger;
  width: 170px;
}

.cancel-routing-banner {
  margin-top: 10px;
}

.approval-subject-history {

  margin-left: 15px;
  margin-right: 15px;

  .expanded {
    border: 2px solid @gray-lighter;
    border-radius: 6px;
    margin-bottom: 15px;
  }

  .approval-subject {
    border-bottom: 1px solid @gray-lighter;
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .approval-subject-expanded {
    border-bottom: 0px solid;
  }

  .approval-path {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-left: 30px;
    border: 1px solid @gray-lighter;
    border-radius: 6px;
  }

  .approvers {
    margin-left: 15px;
    margin-bottom: 15px;
  }

  .approval-organization {
    padding-bottom: 10px;
  }

  .col-md-1 {
    width: 0px;
  }

  .approval-subject-comment {
    margin-left: 15px;
    margin-bottom: 5px;
  }
}

.panel-body.getting-started-panel {
  min-height: 300px;
  position: relative;

  p.select {
    position: absolute;
    bottom: 10px;
    left: -2px;
    right: 0;
  }
}

.initiator-button-size {
  padding-top: 16.6px;
  padding-bottom: 21px;
  width: 168px;
}

.initiator-button-width {
  width: 168px;
}

.actions-menu {
  a.disabled {
    color: #bbb;
    cursor: not-allowed;

    &:hover, &:focus {
      color: #bbb;
    }
  }
}

help-inline.noMarginTop .help-inline-display {
  margin-top: 0;
}

.help-block.text-danger {
  color: @brand-danger;
}

.compliance-help-text {
  margin-top: 15px;
  margin-bottom: 8px;
  padding: 19px;
  background-color: #fafafa;
  color: #222222;
  border: 1px solid #e8e8e8;
}

.ibc-help-text {
  margin-top: -18px;
  margin-bottom: -19px;
  margin-left: -18px;
  background-color: #fafafa;
  color: #222222;
  border: #fafafa;
  box-sizing: unset;
  min-width: 540px;
}

.advanced-search {

  .collapsed-nav-icon{
    width: 100%;
    height: 100%;
    padding: 0;
    display: contents;
    align-items: center;
    justify-content: center;
  }

  a {
    color: #225bb7;
    font-size: 1.1em;
  }

  .title {

    h4 {

      text-align: center;
      i {
        padding-top: 13px;
        font-size: 0.75em;
        vertical-align: 0.2em;
        padding-right: 4px;
        color: #9B9B9B;
      }
    }
  }

  .margin-right {
    margin-right: 8.33%;
  }

  .category-section {
    padding-top: 1em;
  }

  .category-icon {
    display: inline-block;
    color: #C6CCB2;
    font-size: 1em;
    .double-size {
      font-size: 1.5em;
    }
    .disable{
      color: #808080;
    }
    .white-color{
      color:#FFFFFF;
    }
  }

  .top-margin {
    margin-top: 10px;
  }

  .left-margin {
    margin-left: 56px;
  }
  .category-header {
    min-height: 40px;
    h5 {
      color: white;
      margin-top: 12px;
      font-weight: bold;
      i {
        padding-right: 10px;
      }
    }

    .padding {
      padding-left: 6%;
    }

    .header-checkbox {
      display: inline-block;
      color: #C6CCB2;
      margin-top: 10px;
      font-size: 1.7em;
    }

  }
  .category-header-checked {
    background-color: #3A5B6E;
  }
  .category-header-unchecked {
    background-color: #7E6A93;
  }

  .category-body {
    background-color: #F2F2F2;
    margin-left: unset;
    margin-right: unset;
    padding: 1.5em;

    ul {
      list-style-type: none;
      padding-left: initial;
      padding-top: 10px;
    }
    li {
      margin-top: 7px;
      label {
        padding-left: 4px;
      }
    }

    .padding {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    .checkbox-grid li {
      display: block;
      float: left;
      width: 50%;
    }

    .icon-style {
      padding-top: 13px;
      width: 15px;
      height: 17px;
      padding: 0px;
      fill: #878E93;
      display: flex;
      margin-right: 7px;
      margin-left: 4px;
      color: #878E93;
      fill-opacity: 0.3;
    }
    .sponsor-icon-style {
      width: 17px;
    }

    .label-style {
      display: flex;
      justify-content: flex-start;
    }

    .control {
      display: block;
      position: relative;
      padding-left: 23px;
      margin-bottom: 5px;
      cursor: pointer;
    }
    .control input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
    .control_indicator {
      position: absolute;
      top: 2px;
      left: 0;
      height: 13px;
      width: 13px;
      background: #FFFFFF;
      border: 1px solid #9B9B9B;
    }
    .control_indicator {
      border-radius: 2px;
    }

    .control input:checked ~ .control_indicator {
      background: #9B9B9B;
    }

    .control_indicator:after {
      box-sizing: unset;
      content: '';
      position: absolute;
      display: none;
    }
    .control input:checked ~ .control_indicator:after {
      display: block;
    }
    .control-checkbox .control_indicator:after {
      left: 3px;
      top: 0px;
      width: 3px;
      height: 7px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    .note-text{
      color:#525252;
      font-size: 13px;
      font-style: italic;
      padding-top: 20px;
      padding-bottom: 15px;
    }

    .keyword{
      .selectize-input{
        -webkit-border-radius: 0px;
        border-radius: 0px;
        min-height: 39px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      }

    }
    .category-radio{
      .padding{
        padding-bottom: 1em;
        border-bottom: 1px solid #C6CCB2;
        margin-bottom: 1em;
      }

      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label
      {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        display: inline-block;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        border: 1px solid #5C7586;
        border-radius: 100%;
        margin-top: 1px;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 9px;
        height: 9px;
        background: #5C7586;
        position: absolute;
        top: 4px;
        left: 3px;
        border-radius: 100%;
      }
      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      .line-height{
        line-height: 15px;
      }
    }

  }

  .result-text {
    height: 2.4em;
    color: #3A5B6E;
    i{
      font-size: 1.3em;
      line-height: 2.4em;
    }
    button {
      height: 2.5em;
      width: 7em;
      background-color: #3A5B6E;
      border-color: #314d5d;
      color: #FFFFFF;
      border-width: 0px;
    }
    button:disabled {
      cursor: not-allowed;
      background-color: #9B9B9B;
      border: #9B9B9B;
    }
  }
  .search-header {
    margin-top: 15px;
    margin-bottom: 8px;
    padding: 19px;
    .close-button{
    padding-right: 22px;
      font-size: 2.5em;
      color: #9B9B9B;
      opacity: unset;
    }
  }

  .search-footer {

    .save-search {
      background-color: #D6D6D6;
      padding-top: 2em;
      padding-bottom: 1em;
      margin-left: unset;
      margin-right: unset;
      .pad-left {
        padding-left: 37%;
      }
      i {
        font-size: x-large;
        padding-top: 6px;
        margin-left: -15px;
      }
      span {
        color: red;
      }
      input[type="checkbox"] {
        margin-top: 2px;
      }

    }
    .search-link {
      padding-top: 20px;
      padding-bottom: 60px;
      text-align: center;
      button {
        height: 3em;
        width: 10em;
        background-color: #3A5B6E;
        border-color: #314d5d;
        color: #FFFFFF;
        border-width: 0px;
      }
      button:disabled {
        cursor: not-allowed;
        background-color: #9B9B9B;
        border: #9B9B9B;
      }
    }

  }
}

.bulk-operation {
  padding-top: 12px;
}

.link-style {
  color: #225bb7;
  font-size: 1.1em;
}

.delete-associate {

  .body-grid {
    display: grid;
  }

  .text-padding {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.summit-dashboard {
  background-color: #fafafa;
  padding: 30px;
}

.status-comment-margin {
  margin-left: -4px;
}

.pieChartWidgetDisplay {

  .optionsView {

    h5, li {
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }

    h5 {
      margin: 0;

      &:first-child {
        text-decoration: underline;
      }
    }

    li {
      list-style-position: inside;
      white-space: nowrap;
      vertical-align: middle;
      padding-right: 25px;
    }

    ul {
      padding-left: 20px;
      display: inline-block;
      margin-bottom: 0;
    }
  }

  .pieChartContainer {
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .noDataToDisplay {
    margin: 0;
    padding: 10px;
    text-align: center;
  }
}

comments .comments-form-group {
  margin-top: 0;

  #subcontractors & {
    margin-top: 5px;
  }

  #subcontractors tabtastic-container:not(.ng-hide) ~ & {
    margin-top: 25px;
  }

}

.oesrcNotificationButton {
  margin-bottom: @panel-body-padding;
  margin-right: 3.9%;
  white-space: pre-line;
}

#officeOfExportQuestions .panel-body {
  padding-bottom: 0;
}

.no-side-padding{
  padding-left: 0px;
  padding-right: 0px;
}

.row.document-status {
  margin: 10px 0px 8px -15px;
  border-top: 1px solid #ddd;
  width: 105%;

  div[id*="checklist-item"] & {
    width: 111%;
  }
}

.add-item-button {
  margin-bottom: 15px;

  .checklist:not(.noDivider) + & {
    margin-top: -10px;
  }

  .checklist.noDivider + & {
    margin-top: -5px;
  }
}

.link {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}

#proposalDetailsForm {
  approval-view, approval-subject-history {
    padding-left: 0 !important;
  }

  .approval-subject-history {
    margin: 0;

    &:last-child {
      margin-bottom: 15px;

      .expanded {
        margin: 0;
      }
    }
  }
}

#proposalDetailsForm {
  div:first-child > .sectionHeading {
    margin-top: 5px;
  }

  #compliance .sectionHeading.relatedSection {
    margin-top: 0px;
  }

  .sectionHeading {
    display: inline-block;
    margin-top: 35px;

    &.relatedSection {
      margin-top: 5px;
    }
  }

  .disclosureLink {
    margin-top: 5px;
    font-size: 14px;
  }

  .editor-L2-content, .editor-L1-content.checklist-section {
    padding: 0 0 0 @editor-indentation;

    &.editor-table {
      padding-left: 0 !important;
    }

    &.no-introductory-text {
      padding: 5px 0 0 @editor-indentation;
    }

    p[marked="introductoryText"], .introductoryText {
      margin: -5px 0 15px @editor-revert-indentation;
    }

    .introductoryText.letter-code-container {
      margin-left: 0;
      padding-right: 5px;
      text-align: right;
    }
  }

  #destinationAreasWrapper, #initiativesWrapper, #additionalBannerAttributesWrapper {
    .selectize-control.multi .selectize-input > div {
      max-width: 450px;
      width: inherit;

      &.active {
        background: #efefef;
        color: #333333;
      }
    }

    .selectize-control .selectize-input.disabled {
      opacity: 1;
      div {
        background-color: #eeeeee;
        cursor: not-allowed;
        color:#6f6f6f;
      }
    }

    .help-block {
      margin-top: 0;
      margin-bottom: 2px;
    }

    .expunged-delete-button {
      font-weight: 600;
      font-size: 12px;
      margin-left: 10px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.65
      }
    }
  }
}

// Inside Subcontractors section, align Comment label with L2 items while
// leaving the "Add Comments" button aligned with L1
#proposalDetailsForm #subcontractors comments .control-label {
  // L2 margin; ex. calc(15px + ( 0.04 * (100% - 60px)))
  margin-left: ~'calc(15px + (' @editor-indentation-decimal ~'* (100% - 60px)))';

  // L2 checklist col-md-2 width; ex. calc(.1666666667 * ((100% - 61.98px) * (1 -  0.04) + 30px))
  width: ~'calc(.1666666667 * ((100% - 61.98px) * (1 - ' @editor-indentation-decimal ~') + 30px))';
}

form[name="reviseForm"] div.modal-header > p {
  margin: 0;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.hide-spinner {
  /* For Firefox */
    -moz-appearance: textfield;

  /* Webkit browsers like Safari and Chrome */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.modal.preview-generating-modal {
  .modal-dialog {
    transform: translateY(-50%);
    top: 50%;
  }

  .modal-content {
    padding: 50px 50px 20px;
    font-size: 1.5em;
  }
}

.entity-label-option {
  &.deleted .display-name:after  {
    content: " (deactivated)";
  }
}

.markdown-editor {
  .link {
    pointer-events: initial;
    opacity: initial;
  }
}
