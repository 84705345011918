/* ========================================================================
   Component: utils.less
 ========================================================================== */

//
// Utilities classes to simplify
// components constructions
// ------------------------------

@margin-base: 10px;
@margin-sm:   5px;
@margin-md:   10px;
@margin-lg:   15px;
@margin-xl:   30px;

@padding-xl:   30px;
@padding-lg:   15px;
@padding-base: 10px;
@padding-sm:   5px;

@text-alpha:            rgba(255,255,255,.5);
@text-alpha-inverse:    rgba(0,0,0,.5);

@text-sm:               @font-size-base * .85;
@text-md:               @font-size-base * 1.2;
@text-lg:               @font-size-base * 2;

@border-color:          rgba(0,0,0,.12);

@wd-xxs:                60px;
@wd-xs:                 90px;
@wd-sm:                 150px;
@wd-sd:                 200px; // sd == standard
@wd-md:                 240px;
@wd-lg:                 280px;
@wd-xl:                 320px;
@wd-xxl:                360px;
@wd-wide:               100%;
@wd-auto:               auto;
@wd-zero:               0;

// Margins
// --------------------

.m0 { margin: 0 !important; }
.ml0 { margin-left: 0 !important; }
.mr0 { margin-right: 0 !important; }
.mt0 { margin-top: 0 !important; }
.mb0 { margin-bottom: 0 !important; }

.m  { margin: @margin-base !important; }
.ml { margin-left: @margin-base !important; }
.mr { margin-right: @margin-base !important; }
.mt { margin-top: @margin-base !important; }
.mb { margin-bottom: @margin-base !important; }

.m-sm  { margin: @margin-sm !important; }
.ml-sm { margin-left: @margin-sm !important; }
.mr-sm { margin-right: @margin-sm !important; }
.mt-sm { margin-top: @margin-sm !important; }
.mb-sm { margin-bottom: @margin-sm !important; }

.m-md  { margin: @margin-md !important; }
.ml-md { margin-left: @margin-md !important; }
.mr-md { margin-right: @margin-md !important; }
.mt-md { margin-top: @margin-md !important; }
.mb-md { margin-bottom: @margin-md !important; }

.m-lg  { margin: @margin-lg !important; }
.ml-lg { margin-left: @margin-lg !important; }
.mr-lg { margin-right: @margin-lg !important; }
.mt-lg { margin-top: @margin-lg !important; }
.mb-lg { margin-bottom: @margin-lg !important; }

.m-xl  { margin: @margin-xl !important; }
.ml-xl { margin-left: @margin-xl !important; }
.mr-xl { margin-right: @margin-xl !important; }
.mt-xl { margin-top: @margin-xl !important; }
.mb-xl { margin-bottom: @margin-xl !important; }

// Margin vertical / horizontal
.mv { .mt; .mb; }
.mh { .ml; .mr; }

.mv-lg { .mt-lg; .mb-lg; }
.mh-lg { .ml-lg; .mr-lg; }

.mv-sm { .mt-sm; .mb-sm; }
.mh-sm { .ml-sm; .mr-sm; }

// Paddings
// --------------------

.p0 { padding: 0 !important; }
.pl0 { padding-left: 0 !important; }
.pr0 { padding-right: 0 !important; }
.pt0 { padding-top: 0 !important; }
.pb0 { padding-bottom: 0 !important; }
.pv0 { .pt0; .pb0; }
.ph0 { .pl0; .pr0; }

.p  { padding: @padding-base !important; }
.pl { padding-left: @padding-base !important; }
.pr { padding-right: @padding-base !important; }
.pt { padding-top: @padding-base !important; }
.pb { padding-bottom: @padding-base !important; }

.p-sm  { padding: @padding-sm !important; }
.pl-sm { padding-left: @padding-sm !important; }
.pr-sm { padding-right: @padding-sm !important; }
.pt-sm { padding-top: @padding-sm !important; }
.pb-sm { padding-bottom: @padding-sm !important; }

.p-lg  { padding: @padding-lg !important; }
.pl-lg { padding-left: @padding-lg !important; }
.pr-lg { padding-right: @padding-lg !important; }
.pt-lg { padding-top: @padding-lg !important; }
.pb-lg { padding-bottom: @padding-lg !important; }

.p-xl  { padding: @padding-xl !important; }
.pl-xl { padding-left: @padding-xl !important; }
.pr-xl { padding-right: @padding-xl !important; }
.pt-xl { padding-top: @padding-xl !important; }
.pb-xl { padding-bottom: @padding-xl !important; }


// Padding vertical / horizontal
.pv { .pt; .pb; }
.ph { .pl; .pr; }

.pv-xl { .pt-xl; .pb-xl; }
.ph-xl { .pl-xl; .pr-xl; }

.pv-lg { .pt-lg; .pb-lg; }
.ph-lg { .pl-lg; .pr-lg; }

.pv-sm { .pt-sm; .pb-sm; }
.ph-sm { .pl-sm; .pr-sm; }


// Border
// --------------------

.b0 { border-width: 0 !important; }
.bl0 { border-left-width: 0 !important; }
.br0 { border-right-width: 0 !important; }
.bt0 { border-top-width: 0 !important; }
.bb0 { border-bottom-width: 0 !important; }

.br { border-right: 1px solid @border-color;}
.bl { border-left: 1px solid @border-color;}
.bt { border-top: 1px solid @border-color;}
.bb { border-bottom: 1px solid @border-color;}
.b, .ba  { .br;.bl;.bt;.bb; } // all borders

// Border Radius Clear

.radius-clear {
  border-radius: 0 !important;
}

// Box Shadow Clear

.shadow-clear {
  box-shadow: 0 0 0 #000 !important;
}

// Text helpers

// Text sizing

.text-sm { font-size: @text-sm; }
.text-md { font-size: @text-md; }
.text-lg { font-size: @text-lg; }

// Text others

.text-nowrap { white-space: nowrap; }
.text-thin   { font-weight: 100 !important; }
.text-normal { font-weight: normal !important; }
.text-bold   { font-weight: bold !important; }

.inline { display: inline-block !important; }
.block-center { margin: 0 auto; }

// Background image
.bg-center {
  background-position: center center;
  background-size: cover;
}

// List
.list-icon {
  em {
    font-size: 14px;
    width: 40px;
    vertical-align: middle;
    margin: 0;
    display: inline-block;
    text-align: center;
    .transition(all .2s);
    line-height: 30px;
  }
  div:hover em {
    .scale(3, 3)
  }
}

/**
 * Displays hidden content on hover. Add this class to the parent element.
 * Markup
 *   <div class="hover-visible">
 *    <div class="hover-hidden"></div>
 *   </div>
 */

.hover-visible {

  .hover-hidden {
    display: none;
  }

  &:hover .hover-hidden {
    display: block;
    &-inline {
      display: inline-block;
    }
  }
}

//
// MEDIA BOX CLASSIC
//
// since BS 3.3.x it doesn't support fullwidth form-controls so we use
// a copy to make possible the use of the classic component
// -----------------------------------


// Media objects
// Source: http://stubbornella.org/content/?p=497
// --------------------------------------------------


// Common styles
// -------------------------

// Clear the floats
.media-box,
.media-box-body {
  overflow: hidden;
  zoom: 1;
}

// Proper spacing between instances of .media-box
.media-box,
.media-box .media-box {
  margin-top: 15px;
}
.media-box:first-child {
  margin-top: 0;
}

// For images and videos, set to block
.media-box-object {
  display: block;
}

// Reset margins on headings for tighter default spacing
.media-box-heading {
  margin: 0 0 5px;
}


// Media image alignment
// -------------------------

.media-box {
  > .pull-left {
    margin-right: 10px;
  }
  > .pull-right {
    margin-left: 10px;
  }
}


// Media list variation
// -------------------------

// Undo default ul/ol styles
.media-box-list {
  padding-left: 0;
  list-style: none;
}
