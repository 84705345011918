@import (inline) 'ngtoast/dist/ngToast.min.css';
@import (inline) 'ngtoast/dist/ngToast-animations.min.css';

.ng-toast .ng-toast__message .alert {
  box-shadow: 3px 3px 1em #333;
}

.ng-toast ul {
  text-align: left;
  padding-left: 20px;
  list-style: disc;
}

.ng-toast p {
  text-align: left;
  padding-left: 20px;
  max-width: 300px;
}
