// Yeti 3.3.1
// Bootswatch
// -----------------------------------------------------

@import  "bootstrap/less/bootstrap.less";
@import "../variables.less";

@import "bootstrap/less/utilities.less";

body, h1, h2, h3, h4, h5 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

[dropdown-toggle]:hover {
   cursor: pointer;
 }

// Navbar =====================================================================

.navbar {
  border: none;
  font-size: 13px;
  font-weight: 300;

  .navbar-brand img {
    height: 100%;
  }

  .navbar-toggle:hover .icon-bar {
    background-color: #b3b3b3;
  }

  &-collapse {
    border-top-color: @dropdown-divider-bg;
  }

  .btn {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &-form {
    margin-top: 7px;
    margin-bottom: 5px;

    .form-control {
      height: auto;
      padding: @padding-xs-vertical @padding-xs-horizontal;
    }
  }

  .dropdown {

    &-menu {
      border: none;

      > li > a,
      > li > a:focus {
        background-color: transparent;
        font-size: 13px;
        font-weight: 300;
      }
    }

    &-header {
      max-width: 160px;
      color: rgba(255, 255, 255, 0.5);
    }

  }

  &-default {

    .dropdown-menu {
      background-color: @navbar-default-bg;

      > li > a,
      > li > a:focus {
        color: @navbar-default-color;
      }

      > li > a:hover,
      > .active > a,
      > .active > a:hover {
        background-color: @navbar-default-link-hover-bg;
      }
    }

  }

  &-inverse {

    .dropdown-menu {
      background-color: @navbar-inverse-bg;

      > li > a,
      > li > a:focus {
        color: @navbar-inverse-color;
      }

      > li > a:hover,
      > .active > a,
      > .active > a:hover {
        background-color: @navbar-inverse-link-hover-bg;
      }
    }
  }

  h1, h2, h3, h4 {
    margin: 0px;
  }
}

// Buttons ====================================================================

.btn {
  padding: @padding-base-vertical @padding-base-horizontal;

  &-default {
    border-color: #aaa;

    &.disabled {
      color: #777;
    }
  }

  &-lg {
    padding: @padding-large-vertical @padding-large-horizontal;
  }

  &-sm {
    padding: @padding-small-vertical @padding-small-horizontal;
  }

  &-xs {
    padding: @padding-xs-vertical @padding-xs-horizontal;
  }

  &-xxs {
    padding: 0px 4px;
    font-size: 11px;
  }
}

.btn-group {

  .btn ~ .dropdown-toggle {
    padding-left: 16px;
    padding-right: 16px;
  }

  .dropdown-menu {
    border-top-width: 0;
  }

  &.dropup .dropdown-menu {
    border-top-width: 1px;
    border-bottom-width: 0;
    margin-bottom: 0;
  }

  .dropdown-toggle {

    &.btn-default ~ .dropdown-menu {
      background-color: @btn-default-bg;
      border-color: @btn-default-border;

      > li > a {
        color: @btn-default-color;
      }

      > li > a:hover {
        background-color: darken(@btn-default-bg, 8%);
      }
    }

    &.btn-primary ~ .dropdown-menu {
      background-color: @btn-primary-bg;
      border-color: @btn-primary-border;

      > li > a {
        color: @btn-primary-color;
      }

      > li > a:hover {
        background-color: darken(@btn-primary-bg, 8%);
      }
    }

    &.btn-success ~ .dropdown-menu {
      background-color: @btn-success-bg;
      border-color: @btn-success-border;

      > li > a {
        color: @btn-success-color;
      }

      > li > a:hover {
        background-color: darken(@btn-success-bg, 8%);
      }
    }

    &.btn-info ~ .dropdown-menu {
      background-color: @btn-info-bg;
      border-color: @btn-info-border;

      > li > a {
        color: @btn-info-color;
      }

      > li > a:hover {
        background-color: darken(@btn-info-bg, 8%);
      }
    }

    &.btn-warning ~ .dropdown-menu {
      background-color: @btn-warning-bg;
      border-color: @btn-warning-border;

      > li > a {
        color: @btn-warning-color;
      }

      > li > a:hover {
        background-color: darken(@btn-warning-bg, 8%);
      }
    }

    &.btn-danger ~ .dropdown-menu {
      background-color: @btn-danger-bg;
      border-color: @btn-danger-border;

      > li > a {
        color: @btn-danger-color;
      }

      > li > a:hover {
        background-color: darken(@btn-danger-bg, 8%);
      }
    }
  }
}

// Typography =================================================================

.lead {
  color: @gray;
}

cite {
  font-style: italic;
}

blockquote {
  border-left-width: 1px;
  color: @gray;

  &.pull-right {
    border-right-width: 1px;
  }

  small {
    font-size: @font-size-small;
    font-weight: 300;
  }
}

// Tables =====================================================================

table {
  font-size: @font-size-small;
}

// Forms ======================================================================

label,
.control-label,
.help-block,
.error-block,
.checkbox,
.radio {
  font-size: @font-size-small;
  font-weight: normal;
}

input[type="radio"],
input[type="checkbox"] {
  margin-top: 1px;
}

// Navs =======================================================================

.nav {
  .open > a,
  .open > a:hover,
  .open > a:focus {
    border-color: transparent;
  }
}

.nav-tabs {
  > li > a {
    background-color: @btn-default-bg;
    color: @text-color;
  }

  .caret {
    border-top-color: @text-color;
    border-bottom-color: @text-color;
  }
}

.nav-pills {
  font-weight: 300;
}

.breadcrumb {
  border: 1px solid @table-border-color;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 300;
  text-transform: uppercase;
}

.pagination {
  font-size: @font-size-small;
  font-weight: 300;
  color: @gray-light;

  > li {
    > a,
    > span {
      margin-left: 4px;
      color: @gray-light;
    }
  }

  > .active {
    > a,
    > span {
      color: #fff;
    }
  }

  > li,
  > li:first-child,
  > li:last-child {
    > a,
    > span {
      border-radius: 3px;
    }
  }

  &-lg > li > a {
    padding-left: 22px;
    padding-right: 22px;
  }

  &-sm > li > a {
    padding: 0 5px;
  }
}

.pager {
  font-size: @font-size-small;
  font-weight: 300;
  color: @gray-light;
}

.list-group {
  font-size: @font-size-small;
  font-weight: 300;
}

// Indicators =================================================================

.close {
  opacity: 0.4;
  text-decoration: none;
  text-shadow: none;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.alert {
  font-size: @font-size-small;
  font-weight: 300;

  .alert-link {
    font-weight: normal;
    color: #fff;
    text-decoration: underline;
  }
}

.label {
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 0;
  font-weight: 300;

  &-default {
    background-color: @btn-default-bg;
    color: @btn-default-color;
  }
}

.badge {
  font-weight: 300;
}

// Progress bars ==============================================================

.progress {
  height: 22px;
  padding: 2px;
  background-color: #f6f6f6;
  border: 1px solid #ccc;
}

// Containers =================================================================

.dropdown {

  &-menu {
    padding: 0;
    margin-top: 0;
    font-size: @font-size-small;

    > li > a {
      padding: 12px 15px;
    }
  }

  &-header {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 9px;
    text-transform: uppercase;
  }
}

.popover {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}

.panel {
  &-heading,
  &-footer {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &-default {
    .close {
      color: @text-color;
    }
  }

  &.panel-primary .panel-heading {
    color: @panel-primary-text;
  }
}

.modal {
  .close {
    color: @text-color;
  }
  .modal-header {
    background-color: @brand-primary;
    color: white;
  }
  .modal-body {
    position: initial;
  }
}

.modal-backdrop {
  bottom: 0;
  z-index: 1035;
}

.plan-overview {
  margin-bottom: 30px;

  &.faded {
    .plan {
      opacity: .5;

      &.focused {
        opacity: 1;
      }
    }
  }
}

.plan {
  font-size: 1.5em;
  background-color: darken(@body-bg, 10%);
  cursor: pointer;
  transition: opacity .3s;

  .plan-header {
    background-color: @brand-primary;
    color: white;
    text-align: center;
    padding: .5em;
  }

  .plan-features {
    padding: 1em;

    ul {
      margin-bottom: 1.5em;
    }
  }
}

.valdr-message {
  display: none;

  &.ng-valid, &:empty {
    display: none !important;
  }
}

.valdr-form-group.ng-invalid > .valdr-message,
.valdr-message.ng-invalid.ng-touched.ng-dirty, .valdr-message.ng-invalid.ng-untouched,
form.ng-submitted .valdr-message.ng-invalid, form.ng-submitted .valdr-message.ng-invalid,
form.ng-submitted.hide-untouched-error .valdr-message.ng-invalid.ng-untouched,
.hide-untouched-error form.ng-submitted .valdr-message.ng-invalid.ng-untouched,
.hide-untouched-error .valdr-form-group .valdr-message.ng-invalid.ng-touched {
  display: block;
  color: black;
  border: 1px solid @brand-danger;
  padding: 5px 10px 5px 20px;
  border-radius: 5px;
  margin-bottom: 3px;
  margin-top: 1ex;

  &:before {
    content : "\f071";
    font: normal normal normal 14px/1 FontAwesome;
    color: @brand-danger;
    font-size: 12px;
    margin-right: 3px;
    margin-left: -15px;
  }
}

input, select, .twitter-typeahead, vt-people-search, vt-organization-search  {
  + .valdr-message, + .error-danger {
    margin-top: 5px;
  }
}

.hide-untouched-error .valdr-message.ng-invalid.ng-untouched,
.hide-untouched-error .valdr-form-group.ng-invalid > .valdr-message {
  display: none;
}

.form-horizontal {
  ul.form-control-static {
    padding-left: 15px;
  }

  &.condensed-form .form-group {
    margin-bottom: 5px;
  }

  h4.section-title {
    margin-top: 35px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
  }

  vt-date-long-range-picker input {
    text-align: center;
  }
}

.strikeout {
  text-decoration: line-through;
}

.small-text {
  font-size: @font-size-small;
}

.half-opacity {
  opacity: .5;
}

.low-opacity {
  opacity: .3;
}

.error {
  color: @brand-danger;
}

.error-warning, .error-danger, .error-success, .error-info {
  color: #222;
  padding: 5px 5px 5px 23px;
  border-radius: 5px;
  margin-top: 5px;

  &:before {
    content : "\f071\00a0";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 12px;
    margin-right: 3px;
    margin-left: -18px;
    float:left
  }

}

.error-danger {
  border: 1px solid @brand-danger;
  &:before {
    color: @brand-danger;
  }
}

.error-warning {
  border: 1px solid @brand-warning;
  &:before {
    color: @brand-warning;
  }
}

.error-success {
  border: 1px solid @brand-success;
  &:before {
    color: @brand-success;
    content: "\f00c\00a0";
  }
}

.error-info {
  border: 1px solid @brand-info;
  &:before {
    color: @brand-info;
    content: "\f07b\00a0";
  }
}

.help-tooltip-display {
  min-width: 250px;

  &.in {
    opacity : 1;
  }

  .tooltip-inner {
    background-color: @body-bg;
    color: #000000 !important;
    border: 1px solid @brand-info;
    box-shadow: 3px 3px 1em rgba(0,0,0,.3);
    text-align: left;
    max-width: 325px;

    &:before {
      content: "\f05a\00a0";
      font-family: FontAwesome;
      color: @brand-info;
    }

    > div {
      padding-left: 15px;
      margin-top: -15px;
    }

    p:last-child {
      margin-bottom: 0px;
    }
  }
}

help-inline {
  display: none;
}

.help-inline-display {
  margin-top: 15px;
  margin-bottom: 8px;

  padding-left: 25px;
  padding-right: 10px;
  background-color: @body-bg;
  color: @text-color;
  border: 1px solid @brand-info;
  box-shadow: 3px 3px 1em rgba(0,0,0,.3);
  text-align: left;

  &:before {
    content: "\f05a\00a0";
    font-family: FontAwesome;
    color: @brand-info;
    margin-left: -17px;
    float: left;
  }

  p:last-child {
    margin-bottom: 0px;
  }
}

help-inline .disabled {
  color: @gray-light;
}

.ng-toast {
  .error-warning, .error-danger, .error-success {
    background-color: #f2f2f2;
    text-align: left;
    border-width: 2px;
    padding-right: 35px;

    > ul:first-child, > p:first-child {
      margin-top: -15px;
    }

    p {
      padding-left: 0px;
    }
  }
}
