@wz-color-default: #E6E6E6;
@wz-color-current: #808080;
@wz-color-done:    #339933;
@wz-color-editing: #FF0000;

.steps-indicator {
  /* ---- steps quantity ---- */


  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 20px 0 0 0;
  height: 30px;
  list-style: none;
  


  &:before {
    background-color: @wz-color-default;
    content: '';
    position: absolute;
    height: 1px;
  }

  &.steps-2:before {
    left: ~'calc(100% / 2 / 2)';
    right: ~'calc(100% / 2 / 2)';
  }

  &.steps-3:before {
    left: ~'calc(100% / 3 / 2)';
    right: ~'calc(100% / 3 / 2)';
  }

  &.steps-4:before {
    left: ~'calc(100% / 4 / 2)';
    right: ~'calc(100% / 4 / 2)';
  }

  &.steps-5:before {
    left: ~'calc(100% / 5 / 2)';
    right: ~'calc(100% / 5 / 2)';
  }

  &.steps-6:before {
    left: ~'calc(100% / 6 / 2)';
    right: ~'calc(100% / 6 / 2)';
  }

  &.steps-7:before {
    left: ~'calc(100% / 7 / 2)';
    right: ~'calc(100% / 7 / 2)';
  }

  &.steps-8:before {
    left: ~'calc(100% / 8 / 2)';
    right: ~'calc(100% / 8 / 2)';
  }

  &.steps-9:before {
    left: ~'calc(100% / 9 / 2)';
    right: ~'calc(100% / 9 / 2)';
  }

  &.steps-10:before {
    left: ~'calc(100% / 10 / 2)';
    right: ~'calc(100% / 10 / 2)';
  }

  /* --- http://www.paulirish.com/2012/box-sizing-border-box-ftw/ ---- */
  * { 
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
  }


  li {
    position: relative;
    float: left;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    text-align: center;
    line-height: 15px;

    a {
      color: @wz-color-current;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      transition: 0.25s;
      cursor: pointer;

      &:before {
        position: absolute;
        top: -7px;
        left: ~'calc(50% - 7px)';
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background-color: @wz-color-default;
        content: '';
        transition: 0.25s;
      }

      &:hover {
        color: darken(@wz-color-current, 20%);
      }
    }
  }

  &.steps-2  li {
    width: ~'calc(100% / 2)';
  }

  &.steps-3  li {
    width: ~'calc(100% / 3)';   
  }

  &.steps-4  li {
    width: ~'calc(100% / 4)';
  }

  &.steps-5  li {
    width: ~'calc(100% / 5)';
  }

  &.steps-6  li {
    width: ~'calc(100% / 6)';
  }

  &.steps-7  li {
    width: ~'calc(100% / 7)';
  }

  &.steps-8  li {
    width: ~'calc(100% / 8)';
  }

  &.steps-9  li {
    width: ~'calc(100% / 9)';
  }

  &.steps-10 li  {
    width: ~'calc(100% / 10)';
  }

  &.steps-11 li  {
    width: ~'calc(100% / 11)';
  }

  li.default {
    pointer-events: none;

    a:hover {
      color: @wz-color-current;
    }
  }

  li.current,
  li.editing {
    pointer-events: none;
  }

  li.current a:before {
    background-color: @wz-color-current;
  }

  li.done a:before {
    background-color: @wz-color-done;
  }

  li.editing a:before {
    background-color: @wz-color-editing;
  }
}
