@-webkit-keyframes bounceInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(2000px);
	}
	
	60% {
		opacity: 1;
		-webkit-transform: translateY(-30px);
	}
	
	80% {
		-webkit-transform: translateY(10px);
	}
	
	100% {
		-webkit-transform: translateY(0);
	}
}
@-moz-keyframes bounceInUp {
	0% {
		opacity: 0;
		-moz-transform: translateY(2000px);
	}
	
	60% {
		opacity: 1;
		-moz-transform: translateY(-30px);
	}
	
	80% {
		-moz-transform: translateY(10px);
	}
	
	100% {
		-moz-transform: translateY(0);
	}
}

@-o-keyframes bounceInUp {
	0% {
		opacity: 0;
		-o-transform: translateY(2000px);
	}
	
	60% {
		opacity: 1;
		-o-transform: translateY(-30px);
	}
	
	80% {
		-o-transform: translateY(10px);
	}
	
	100% {
		-o-transform: translateY(0);
	}
}

@keyframes bounceInUp {
	0% {
		opacity: 0;
		transform: translateY(2000px);
	}
	
	60% {
		opacity: 1;
		transform: translateY(-30px);
	}
	
	80% {
		transform: translateY(10px);
	}
	
	100% {
		transform: translateY(0);
	}
}

.bounceInUp {
	-webkit-animation-name: bounceInUp;
	-moz-animation-name: bounceInUp;
	-o-animation-name: bounceInUp;
	animation-name: bounceInUp;
}