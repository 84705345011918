@import 'variables.less';

@media (min-width: 1100px) {
  .preference-modal-window > .modal-dialog {
    width: 1050px;
  }
}

#notification-preferences {
  //flex-direction: column;
  //height: 100%;
  //display: flex;
  .main-header {
    background: #3A5B6E;
    padding: 40px 0;
    display: flex;
    color: #fff;
    justify-content: space-around;
    .email-notification {
      display: flex;
      flex-direction: column;
      .page-title {
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 5px 0;
      }
      .email-disclaimer {
        i {
          font-size: 18px;
          padding: 0 5px 0 0;
          color: #fbff00;
        }
      }
    }
    .notification-buttons {
      align-items: center;
      display: flex;
    }
    .cancel-btn {
      background: #F8F8F8;
      border: none;
      padding: 10px 35px;
      color: #78935C;
    }
    .save-btn {
      background: #78935C;
      border: none;
      padding: 10px 35px;
      margin: 0 0 0 10px;
    }
    .notification-keys {
      display: flex;
      align-items: center;
      font-size: 15px;
      .off-key {
        margin: 0 0 0 10px;
      }
      .symbol {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  .notification-preferences-tabs {
    display: flex;
    height: 100%;
    .tabs-view {
      width: 240px;
      flex-basis: auto;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      background: #E5E5E5;
      border-right: #ccc solid 1px;

      .tab {
        padding: 30px 0 30px 35px;
        font-size: 15px;
        text-transform: uppercase;
        border-bottom: solid 1px #ddd;
        border-top: solid 1px #ddd;
        background: #F8F8F8;
        display: flex;
        justify-content: space-between;
        border-left: 5px solid transparent;
        align-items: center;
        span {
          color: #6d6d6d;
        }
        i {
          margin: 6px 25px 0 0;
          font-size: 10px;
          color: #a7a7a7;
        }
        &.active {
          background: #fff;
          border-left-color: #3A5B6E;
          padding-left: 38px;
          span,i {
            color: #3A5B6E;
            font-weight: bold;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: #fff;
        }
      }
    }
    .notification-preferences-view {
      margin: 30px 40px 10px 40px;
      flex: auto;
      min-height: 200px;

      .tab-description {
        margin-bottom: 15px;
        border-left: 5px solid @brand-primary;
        padding: 15px;
      }

      .notification-preferences-container-notifications {
        border-bottom: #e6e6e6 solid 1px;
        margin: 0 30px;
        padding: 20px 0;
        color: #525252;
        display: flex;
        justify-content: space-between;
        > .notification-content {
          margin-right: 50px;

          > .notification-title {
            font-weight: bold;
            font-size: 15px;

            &.no-help-text {
              padding-top: 8px;
            }
          }
          .notification-instructions {
            font-size: 13px;
            font-style: italic;
          }
        }
        .notification-action {
          display: flex;
          align-items: center;
          min-width: 270px;
          text-align: right;
          color: #666;

          .form-group {
            margin-bottom: 0;
          }

          .fa {
            font-size: 30px;
          }

          .fa-toggle-on, .fa-check-square-o {
            color: #3A5B6E;
          }

          > * {
            width: 100%;
            text-align: right
          }
          .email-address{
            min-width : 275px;
          }
        }
      }

      .notification-preferences-container-other {
        border-bottom: #e6e6e6 solid 1px;
        margin: 0 30px;
        padding: 20px 0;
        color: #525252;
        display: flex;
        justify-content: space-between;
        > .notification-content {
          margin-right: 50px;

          > .notification-title {
            font-weight: bold;
            font-size: 15px;

            &.no-help-text {
              padding-top: 8px;
            }
          }
          .notification-instructions {
            font-size: 13px;
            font-style: italic;
          }
        }
        .notification-action {
          display: flex;
          align-items: center;
          min-width: 330px;
          text-align: right;
          color: #666;

          .form-group {
            margin-bottom: 0;
          }

          .fa {
            font-size: 30px;
          }

          .fa-toggle-on, .fa-check-square-o {
            color: #3A5B6E;
          }

          > * {
            width: 100%;
            text-align: right
          }
        }
      }




      preference-group .notification-header {
        .notification-preferences-container {
          padding: 20px 0;
        }

        .notification-content .notification-title {
          padding: 0 0;
          font-size: 22px;
        }
      }

      .notification-header {
        background: #E1F2FA;
        .notification-title {
          display: block;
          font-size: 22px;
          color: #3B5866;
          padding: 20px 30px 0 30px;
          font-weight: bold;
        }
        .notification-desc {
          display: block;
          font-style: italic;
          padding: 0 30px 20px 30px;
          color: #5a5a5a;
        }
      }
    }
  }
}

preference-category, preference-group, preference-item, preference-item-toggle, .notification-action > * {
  display: block;
}

.preference-group {
  margin-bottom: 40px;
}

preference-item-toggle .fa {
  cursor: pointer;
}


preference-group, preference-item {
  border: #d6d6d6 solid 1px;
}

preference-group {
  margin: 10px 0;

  preference-item {
    border: none;
  }
}

preference-item.standalone-item {
  margin-bottom: 30px;
}

preference-item .disabled .fa {
  color: #ccc !important;
  cursor: default;
}

preference-item.standalone-item, preference-group preference-item:last-child {
  .notification-preferences-container {
    border-bottom: 0 !important;
  }
}

preference-item .form-group {
  margin-left: 0 !important;
  margin-right:0 !important;
}
