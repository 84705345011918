@import 'angular-wizard/dist/angular-wizard.less';
@import '../variables.less';

.steps-indicator {
  height: 60px;

  li a:before, li a {
    cursor: default;
    transition: .75s;
  }

  li.current a {
    color: @brand-primary;
    &:before {
      background-color: @brand-primary;
    }
  }

  li.done a {
    color: lighten(@brand-primary, 20%);
    &:before {
      background-color: lighten(@brand-primary, 20%);
    }
  }
}

.steps {
  padding-top: 20px;
}